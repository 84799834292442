import React, {
  FunctionComponent,
  useRef,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, IconType } from '../Icon';
import { ApplicationMode, ClipDetailWithMatch } from '../../types';
import { useDispatch, useSelector } from 'react-redux';
import { getApplicationModeState, getUser } from '../../selectors';
import { classHandler, iconTypeHandler } from '../../utils';

import './ClipDetailTitle.scss';
import { Button } from '../Button';
import { ClickAwayListener, Popper } from '@material-ui/core';
import { MatchDetailVideoResyncTimeModal } from '../MatchDetailVideoResyncTimeModal';
import useDownloadClip from '../../utils/useDownloadClip';
import { DeleteAndNextModal } from '../DeleteAndNextModal';
import ReportVideoModal from '../ReportVideoModal/ReportVideoModal';
import { setLoginRequiredModalVisibility } from '../../actions';
import { LoginRequiredModal } from '../LoginRequiredModal';

type ClipDetailTitle = {
  newClipDetail: ClipDetailWithMatch | null;
  goBack: () => void;
  hideDelete?: boolean;
  handleFavourite?: () => void;
  handleShare?: () => void;
  withRedirect: boolean;
  redirectToMatchDetail: () => void;
  clipShared?: boolean;
  setClipDetail: Dispatch<SetStateAction<ClipDetailWithMatch | null>>;
  clipSyncTime: number;
  setClipSyncTime: Dispatch<SetStateAction<number>>;
  setForceStopPlaying: Dispatch<SetStateAction<boolean>>;
  showDeleteNext: boolean;
  handleDelete: (result: boolean, goToNext?: boolean) => void;
  keyboardShortcutDisabled: boolean;
};

export const ClipDetailTitle: FunctionComponent<ClipDetailTitle> = ({
  newClipDetail,
  goBack,
  hideDelete,
  handleFavourite,
  handleShare,
  withRedirect,
  redirectToMatchDetail,
  clipShared,
  setClipSyncTime,
  clipSyncTime,
  setForceStopPlaying,
  showDeleteNext,
  handleDelete,
  keyboardShortcutDisabled,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const applicationMode = useSelector(getApplicationModeState);
  const user = useSelector(getUser);
  const [isConfirmVisible, setConfirmVisible] = useState<boolean>(false);
  const [isResyncModalVisible, setResyncModalVisible] = useState(false);
  const [tooltipVisibility, setTooltipVisible] = useState<boolean>(false);
  const [isReportVisible, setReportVisible] = useState<boolean>(false);

  const moreButtonRef = useRef<HTMLDivElement>(null);

  const iconType = applicationMode === ApplicationMode.light ? IconType.default : IconType.white;

  const [syncEntry] = useState<number>(0);

  const { handleDownload } = useDownloadClip(newClipDetail);

  const handleConfirmVisibilityChange = () => {
    setConfirmVisible(!isConfirmVisible);
  };

  const handleTooltipVisibility = () => {
    setTooltipVisible(!tooltipVisibility);
  };

  const handleReportVisibility = () => {
    if (user) {
      setReportVisible(prev => !prev);
    } else {
      dispatch(setLoginRequiredModalVisibility(true));
    }
  };

  const openModal = () => {
    setResyncModalVisible(!isResyncModalVisible);
    handleTooltipVisibility();
    setForceStopPlaying(true);
  };

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (keyboardShortcutDisabled) return;

      switch (event.key) {
        case 'd':
        case 'D':
          setConfirmVisible(prev => !prev);
          break;
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyboardShortcutDisabled, setConfirmVisible]);

  if (!newClipDetail) return null;

  const setProxy = (videoUrl: string): string => {
    const tvCom = 'http://static.tvcom.cz/';
    const shouldUseProxy = RegExp(tvCom);
    if (shouldUseProxy.test(videoUrl)) {
      const proxy = 'https://proxy.refrec.k42.dev/tvcom/';
      return videoUrl.replace(tvCom, proxy);
    }
    return videoUrl;
  };

  const setClipResync = (syncTime: number) => {
    setClipSyncTime(syncTime);
  };

  return (
    <div className={classHandler('ClipDetailTitle', applicationMode)}>
      <DeleteAndNextModal
        isConfirmVisible={isConfirmVisible}
        handleDelete={handleDelete}
        showNext={showDeleteNext}
        handleClose={handleConfirmVisibilityChange}
      />
      <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
        <div className="ClipDetailTitle__backButton" onClick={goBack}>
          <Icon
            size="20px"
            name="arrowBack"
            type={iconTypeHandler(applicationMode, IconType.white)}
          />
        </div>
        <h2 className="ClipDetailTitle__tabletTitleHidden">{t('videoClipDetail')}</h2>
      </div>
      <div className="ClipDetailTitle__buttons">
        <Button
          label={t('addToFavourites')}
          type="basic"
          onClick={handleFavourite}
          className=""
          iconName={newClipDetail.isFavorite ? 'heartFull' : 'heart'}
          iconPosition="right"
          iconType={
            newClipDetail.isFavorite
              ? IconType.default
              : iconTypeHandler(applicationMode, IconType.white)
          }
          theme={applicationMode}
        />
        <Button
          className={`ClipDetailTitle__button ${
            clipShared ? `ClipDetailTitle__button--green` : ''
          }`}
          onClick={handleShare}
          label={clipShared ? t('shareCopied') : t('share')}
          iconName={'share'}
          iconPosition="right"
          iconType={iconTypeHandler(applicationMode, IconType.white, IconType.white, clipShared)}
        />
        <Button
          label={t('downloadVideo')}
          type="basic"
          onClick={handleDownload}
          rightIcon={
            <Icon
              size={applicationMode === ApplicationMode.dark ? '18px' : '25px'}
              name={'downloadOutline'}
              type={iconTypeHandler(applicationMode, IconType.white)}
            />
          }
          iconPosition="right"
          theme={applicationMode}
        />

        {!hideDelete && (
          <Button
            label={t('delete')}
            type="basic"
            onClick={handleConfirmVisibilityChange}
            className=""
            disabled={false}
            iconName={'trash'}
            iconPosition="right"
            iconType={iconTypeHandler(applicationMode, IconType.white)}
            premiumFeature={false}
            theme={applicationMode}
          />
        )}
        {withRedirect && (
          <Button
            label={t('matchDetail')}
            onClick={redirectToMatchDetail}
            iconName="arrowForward"
            iconType={iconTypeHandler(applicationMode, IconType.white)}
            type="basic"
            iconPosition="left"
            theme={applicationMode}
          />
        )}

        <div>
          <div
            onClick={handleTooltipVisibility}
            className={`ClipDetailTitle__button Button__basic ${applicationMode === 'dark' &&
              `ClipDetailTitle__button--dark`}`}
            ref={moreButtonRef}
          >
            <Icon size="15px" name="more" type={iconTypeHandler(applicationMode, IconType.white)} />
            <Popper
              open={!!tooltipVisibility}
              anchorEl={moreButtonRef.current}
              role={undefined}
              placement="left-start"
              onClick={handleTooltipVisibility}
              modifiers={{
                computeStyle: {
                  gpuAcceleration: false,
                  adaptive: false,
                },
              }}
            >
              <div className={classHandler('PopperShadow', applicationMode)}>
                {
                  <ClickAwayListener onClickAway={handleTooltipVisibility}>
                    <div
                      className={classHandler(
                        'MatchDetailVideoContent__container__moreTooltip',
                        applicationMode,
                        false,
                        tooltipVisibility
                      )}
                    >
                      {/* Dropdown */}
                      {/* Don't display the dropdown if it's not the user's video. */}
                      {!hideDelete && (
                        <div className={'MatchDetailVideoContent__container__tooltipButton'}>
                          <div className="MatchDetailVideoContent__container__tooltipButtonLabel">
                            <div
                              onClick={openModal}
                              className="ClipController__tooltipButton"
                              style={{ padding: 5 }}
                            >
                              <Icon size="20px" type={iconType} name="synchronization" />
                              <div className="ClipController__tooltipButtonLabel">
                                {t('useClipForResync')}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {newClipDetail.ownerId !== user?.id && newClipDetail.id && (
                        <div className={'MatchDetailVideoContent__container__tooltipButton'}>
                          <div className="MatchDetailVideoContent__container__tooltipButtonLabel">
                            <div
                              onClick={handleReportVisibility}
                              className="ClipController__tooltipButton"
                              style={{ padding: 6 }}
                            >
                              <Icon size="15px" type={iconType} name="flag" />
                              <div
                                style={{ marginLeft: 12 }}
                                className="ClipController__tooltipButtonLabel"
                              >
                                {t('reportVideo')}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </ClickAwayListener>
                }
              </div>
            </Popper>
          </div>
        </div>
        <MatchDetailVideoResyncTimeModal
          newMatch={newClipDetail}
          sync={newClipDetail}
          setClipResync={setClipResync}
          syncEntry={syncEntry}
          setProxy={setProxy}
          isVisible={isResyncModalVisible}
          _setModalVisible={setResyncModalVisible}
          showConfirmation
          clipSyncTime={clipSyncTime}
          setForceStopPlaying={setForceStopPlaying}
        />
        <ReportVideoModal handleClose={handleReportVisibility} isVisible={isReportVisible} />
        <LoginRequiredModal />
      </div>
    </div>
  );
};
