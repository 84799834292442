import { AccountingFilter, ClipReaction } from '../types';
import { Pagination } from '../types/Pagination';

export const API_URL = process.env.REACT_APP_API_URL || 'https://api.refrec.k42.dev';
export const VIDEO_URL = process.env.REACT_APP_VIDEO_URL || 'https://videoconverter.refrec.k42.dev';

export const getAssetUrl = (url?: string): string => `${API_URL}${url}`;

export const GET_USER_URL = `${API_URL}/users/me`;
export const GET_IS_PREMIUM_UNTIL = (id: number) => `${API_URL}/users/get-is-premium-until/${id}`;
export const PUT_USER_URL = (id: number) => `${API_URL}/users/${id}`;
export const POST_USER_APP_INFO = `${API_URL}/users/app-info`;
export const POST_LOGIN_URL = `${API_URL}/auth/local`;
export const POST_REGISTRATION_URL = `${API_URL}/auth/local/register`;
export const GET_COUNTRIES_URL = `${API_URL}/default-countries?_sort=name:ASC`;
export const GET_SPORTS_URL = `${API_URL}/default-sports?_sort=name:ASC`;
export const GET_TIPS_URL = `${API_URL}/tips`;
export const GET_REFEREE_TEAM_TYPES = `${API_URL}/default-referee-team-types`;
export const GET_REFEREE_TEAM_TYPES_BY_SPORT = (sportId: number) =>
  `${API_URL}/default-sports/${sportId}`;

export const POST_NEW_MATCH_DETAIL_URL = `${API_URL}/matches`;
export const VALIDATE_MATCH_URL_URL = `${API_URL}/match-url-validate`;

export const GET_LANGUAGES_URL = `${API_URL}/default-languages`;
export const PUT_SETTINGS_URL = `${API_URL}/users/settings`;
export const GET_MY_CLIPS_URL = (pagination: Pagination, filters: string) =>
  `${API_URL}/match-clips-grouped/?_start=${pagination.start}&_limit=${pagination.limit}&_sort=date:DESC${filters}`;
export const GET_COMMUNITY_CLIPS_URL = (
  pagination: Pagination,
  clipCategoryType: string,
  filters: string
) =>
  `${API_URL}/match-clips-public/?_start=${pagination.start}&_limit=${pagination.limit}&_sort=matchDate:DESC&clipCategories.type=${clipCategoryType}${filters}`;

export const GET_CLOSED_COMMUNITY_CLIPS_URL = (
  pagination: Pagination,
  clipCategoryType: string,
  filters: string,
  ownerId: number | undefined = 0
) =>
  `${API_URL}/closed-communities/get-clips/${ownerId}?_start=${pagination.start}&_limit=${pagination.limit}&_sort=matchDate:DESC&clipCategories.type=${clipCategoryType}${filters}`;

export const GET_MY_FAVOURITE_CLIPS_URL = (
  pagination: Pagination,
  filters: string,
  ownerId: number | undefined = 0
) =>
  `${API_URL}/favorite-clips/?_start=${pagination.start}&_limit=${pagination.limit}&_sort=matchDate:DESC${filters}&ownerId=${ownerId}`;
export const GET_OTHERS_FAVOURITE_CLIPS_URL = (
  pagination: Pagination,
  filters: string,
  ownerId: number | undefined = 0
) =>
  `${API_URL}/favorite-clips/?_start=${pagination.start}&_limit=${pagination.limit}&_sort=matchDate:DESC${filters}&ownerId_ne=${ownerId}`;

export const GET_CLIP_CATEGORIES_URL = (sport: number) =>
  `${API_URL}/default-clip-categories/?sport.id=${sport}&_sort=id:ASC`;

export const GET_MATCHES_URL = (filter: string, pagination: Pagination, sort: 'ASC' | 'DESC') =>
  `${API_URL}/matches/?${filter}&_start=${pagination.start}&_limit=${pagination.limit}&_sort=date:${sort}`;

export const GET_CLIP_DETAIL_URL = (clipId: number) => `${API_URL}/match-clips/${clipId}`;
export const GET_CLIP_DOWNLOAD_URL = () => `${VIDEO_URL}/v2/clip/`;

export const PUT_SHARE_CLIP_URL = (clipId: number) => `${API_URL}/clip-share/${clipId}`;
export const GET_SHARED_CLIP_URL = (clipId: number, shareToken: string) =>
  `${API_URL}/clip-shared/${clipId}/${shareToken}`;

export const SHARE_CLOSED_COMMUNITY_URL = `${API_URL}/closed-communities/share`;
export const GET_CLOSED_COMMUNITY_NAME_URL = (userId: number) =>
  `${API_URL}/closed-communities/get-name/${userId}`;

export const GET_REACT_TO_CLIP_URL = (action: ClipReaction, clipId: number) =>
  `${API_URL}/video-ratings/${action}/${clipId}`;

export const GET_DOWNLOAD_ALL_CLIPS_URL = (matchId: number) =>
  `${VIDEO_URL}/v2/match/${matchId}/archive`;

export const GET_ALL_CLIPS_FILE_URL = (matchId: number, userId: number) =>
  encodeURI(`${VIDEO_URL}/v2/match/download/${userId}…${matchId}`);

export const GET_SHARED_MATCH_URL = (matchId: number) => `${API_URL}/matches/share/${matchId}`;

export const GET_COMPETITIONS_URL = (userId: number) =>
  `${API_URL}/competitions/?user.id=${userId}&_sort=id:ASC`;

export const GET_MATCH_DETAIL_URL = (matchId: number) => `${API_URL}/matches/${matchId}`;
export const POST_MATCH_DETAIL_URL = (matchId: number) => `${API_URL}/matches/${matchId}`;

export const GET_SHARED_MATCH_CLIPS_URL = (shareToken: string, filters: string) =>
  `${API_URL}/match-clips-grouped/${shareToken}?_start=0&_limit=-1&_sort=date:DESC${filters}`;

export const PUT_CATEGORY_URL = (categoryId: number) => `${API_URL}/categories/${categoryId}`;
export const PUT_COMPETITION_URL = (competitionId: number) =>
  `${API_URL}/competitions/${competitionId}`;
export const POST_COMPETITION_URL = `${API_URL}/competitions`;
export const POST_CATEGORY_URL = `${API_URL}/categories`;
export const DELETE_COMPETITION_URL = (competitionId: number) =>
  `${API_URL}/competitions/${competitionId}`;
export const DELETE_CATEGORY_URL = (categoryId: number) => `${API_URL}/categories/${categoryId}`;

export const CHUNKED_FILE_UPLOAD_URL = `${VIDEO_URL}/v2/video`;

export const GET_MATCH_NOTES_URL = `${API_URL}/match-notes`;

export const GET_ACCOUNTING_SEASONS_URL = `${API_URL}/accounting/seasons`;
export const GET_ACCOUNTING_SEASON_URL = (season: string, filter: AccountingFilter) => {
  let url = `${API_URL}/accounting/season/${season}?`;
  const params: string[] = [];

  if (filter.dateFrom) params.push(`date_gt=${filter.dateFrom.getTime()}`);
  if (filter.dateTo) params.push(`date_lt=${filter.dateTo.getTime()}`);
  if (filter.type !== '' && filter.type.split(',').length === 2) {
    params.push(`accountings.receivedAs=${filter.type.trim().replace(',', '')}`);
  }

  params.forEach(x => {
    url = url.concat(`${x}&`);
  });

  return url;
};

export const GET_STATISTICS_SEASONS_URL = `${API_URL}/stats/seasons`;
export const GET_STATISTICS_SEASON_URL = (season: string) => `${API_URL}/stats/season/${season}`;

export const POST_PREMIUM_RECEIPT_URL = `${API_URL}/users/verify-receipt`;

export const POST_REPORT_CLIP_URL = (clipId: number) => `${API_URL}/video-reports/report/${clipId}`;
